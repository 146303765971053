
/**
 * @name: add
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-网红视频管理-添加，修改
 * @update: 2023-06-01 11:21
 */
import {Component, Vue, Watch} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import {Message} from "element-ui";
import config from "@/config";
import Basic from "./components/Basic.vue"
import Details from "./components/Details.vue"
import {createCourseApi, getCourseDetailApi, modifyCourseApi,} from "@/apis/coursesManage/courses";
import {CourseTypes, ICourse} from "@/apis/coursesManage/courses/types";
import router from "@/router";
import {nextTick} from "vue";

@Component({
  components: {Basic,  Details},
  name:'internetCelebrityAdd'
})
export default class ProductAddPage extends Vue {

  config = config;
  //tab
  activeTabsName = 'first';
  id = ''
  // 新增修改表单
  modelForm: ICourse = {
    status:1
  }

  rules:any = {
    name: [
      { required: true, message: '请输入网红陪玩标题', trigger: 'blur' }
    ],
    price: [
      { required: true, message: '请输入预定金额', trigger: 'blur' }
    ],
    enrollQty: [
      {required: true, message: '请输入报名名额', trigger: 'blur'}
    ],
    courseBannerPic: [
      {required: true, message: '请上传商品轮播图片', trigger: 'blur'}
    ],
    courseVideo: [
      {required: true, message: '请上传课程视频', trigger: 'blur'}
    ],
    sort: [
      {required: true, message: '请选择排序', trigger: 'change'}
    ],

  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * tab
   * @param tab
   * @param event
   */
  handleTabsClick(tab: any, event: any) {
    console.log(tab, event);

  }
  /**
   * submitFinish
   *
   * @param event
   */
  submitFinish(form:any,event: any) {
    this.modelForm = Object.assign(this.modelForm, form);
    this.modelForm.courseTypes=CourseTypes.internet;
    let bol = false;
    // @ts-ignore
    this.$refs.basicRef.$refs.modelForm.validate(async valid => {
      if (valid) {
        // if(this.modelForm.courseVideoSkuDtoList||[].length>0){
        //   bol = true;
        // }else {
        //   bol = false;
        //   Message.error('规格信息不完善！')
        //   this.activeTabsName = 'second'
        // }
        bol = true;
      } else {
        Message.error('基础信息不完善！')
        nextTick(() => {
          this.activeTabsName = 'first'
        })
        return
      }
    })
    if (bol) {
      if (this.modelForm.id) {
        modifyCourseApi(this.modelForm).then(e => {
          if (e) {
            Message.success('编辑成功！')
            this.$store.dispatch('delView', {path: '/coursesManage/internetCelebrity/add', name: "internetCelebrityAdd"});
            router.push({path: '/coursesManage/internetCelebrity/index'})
          }
        })
      } else {
        createCourseApi(this.modelForm).then(e => {
          if (e) {
            Message.success('新增成功！')
            this.$store.dispatch('delView', {path: '/coursesManage/internetCelebrity/add',name: "internetCelebrityAdd"});
            router.push({path: '/coursesManage/internetCelebrity/index'})
          }
        })
      }
    }
  }

  getDetail() {
    if (!this.modelForm.id)
      return
      getCourseDetailApi(this.modelForm.id).then(e => {
        this.modelForm = e;
        if(this.modelForm.courseVideoSkuVoList){
          this.modelForm.courseVideoSkuDtoList=this.modelForm.courseVideoSkuVoList;
        }
      })
  }

  /**
   * 商品详情信息,商品规格信息,商品基础信息
   * @param form
   */
  handleNext(form: IProduct, tab: string) {
    this.modelForm = Object.assign(this.modelForm, form);
    this.activeTabsName = tab;

  }
  async created() {
    const {id} = this.$route.query;
    this.modelForm.id=id;
    await this.getDetail();

  }
}
